<!--
 * @Description: 系统管理 系统角色管理 菜单树组件 systemRoleManagement
 * @Author: yanxiao
 * @Github: 
 * @Date: 2021-04-23 14:40:08
 * @LastEditors: zhoucheng
-->
<template>
  <div>
    <div class="tree-box">
      <el-tree ref="tree"
               :props="treeProps"
               :default-checked-keys="NodeList"
               :load="loadNode"
               lazy
               node-key="id">
      </el-tree>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
  },
  data () {
    //这里存放数据
    return {
      treeProps: {
        id: 'id',
        label: 'label',
        children: 'zones',
        isLeaf: 'leaf'
      },
      NodeList: []
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () {
  }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    loadNode (node, resolve) {
      let info = {}
      if (node.level == 0) {
        info = {
          parentId: ''
        }
        this.$systemMenuManagement.queryMenuList(info).then((response) => {
          let dataList = response.resultEntity
          let data = []
          dataList.forEach(item => {
            if (item.parentId === '') {
              data.push({ id: item.menuId, label: item.menuName, })
            }
          })
          resolve(data)
        }).catch((error) => {
          console.error('获取菜单错误', error)
        })
      } else if (node.level > 0 && node.key) {
        info = {
          parentId: node.key
        }
        this.$systemMenuManagement.queryMenuList(info).then((response) => {
          let dataList = response.resultEntity
          let data = []
          dataList.forEach(item => {
            data.push({ id: item.menuId, label: item.menuName, })
          })
          resolve(data)
        }).catch((error) => {
          console.error('获取菜单错误', error)
        })
      } else {
        resolve([])
      }
    },
  },
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.tree-box {
  height: 500px;
  overflow: auto;
}
.but-box {
  margin: 20px;
}
.tree-box::-webkit-scrollbar {
  width: 2px;
  height: 10px;
}
.tree-box::-webkit-scrollbar-track {
  background: #0a2041;
  border-radius: 2px;
}
.tree-box::-webkit-scrollbar-thumb {
  background: #0065bd;
  border-radius: 10px;
}
</style>