<!--
 * @Description: 系统管理 系统菜单管理 systemMenuManagement
 * @Author: linfeng
 * @Github: 
 * @Date: 2021-03-24 14:44:16
 * @LastEditors: houjinduo
-->
<template>
  <div class="mainbody">
    <el-row class="topSelectTwoLine">
      <el-form :inline="true" :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px" label="菜单名称">
              <el-input
                v-model="searchForm.menuName"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topButton">
          <el-button type="primary" @click="queryButton()">查 询</el-button>
          <el-button type="info" @click="resetForm" style="margin-left: 17px"
            >重 置</el-button
          >
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <el-col :span="21">菜单列表</el-col>
        <el-col :span="3">
          <el-row type="flex" justify="end">
            <el-button type="primary" @click="handleClickADD(null)"
              >新 增</el-button
            >
            <el-button type="primary" @click="menuTreeShow = true"
              >菜单预览</el-button
            >
          </el-row>
        </el-col>
      </el-row>
      <!-- 表格 -->
      <el-row class="tableContent">
        <dt-table
          :tableColumnList="tableColumnList"
          :data="tableList"
          :map="listMap"
          :tableConfig="tableConfig"
          :paginationConfig="paginationConfig"
          @getList="getList"
        >
          <!-- <template slot="isFirst">
            <el-table-column type="selection"></el-table-column>
          </template> -->
          <template slot="operating">
            <el-table-column label="操作" class-name="class-name">
              <template slot-scope="scope">
                <el-col :span="10">
                  <el-button
                    type="text"
                    size="medium"
                    @click="handleClickADD(scope.row)"
                    >新增子菜单</el-button
                  >
                </el-col>
                <el-col :span="7">
                  <el-button
                    type="text"
                    size="medium"
                    @click="handleOrderUpdate(scope.row)"
                    >修改</el-button
                  >
                </el-col>
                <el-col :span="7">
                  <el-button
                    type="text"
                    size="medium"
                    @click="handleOrderDelete(scope.row)"
                    >删除</el-button
                  >
                </el-col>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <!-- 新增弹窗 -->
    <!-- 新增、修改弹出层 0为新增 1为修改 -->
    <el-row>
      <el-dialog
        :title="this.dialogTitle"
        :visible.sync="dialogFormVisible"
        @close="popClosed"
        append-to-body
      >
        <el-form :model="addMenuForm" ref="menuForm" :rules="rules">
          <el-row>
            <el-col :span="12">
              <el-form-item
                label="菜单ID"
                class="formItem"
                prop="menuId"
                :label-width="formLabelWidth"
              >
                <el-input
                  v-if="changeDialogType == 0"
                  v-model="addMenuForm.menuId"
                ></el-input>
                <el-input
                  v-else
                  v-model="addMenuForm.menuId"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="菜单名称"
                class="formItem"
                prop="menuName"
                :label-width="formLabelWidth"
              >
                <el-input v-model="addMenuForm.menuName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item
                label="菜单地址"
                class="formItem"
                prop="menuUrl"
                :label-width="formLabelWidth"
              >
                <el-input v-model="addMenuForm.menuUrl"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="上级菜单"
                class="formItem"
                prop="parentId"
                :label-width="formLabelWidth"
              >
                <el-input v-model="addMenuForm.parentId"></el-input>
                <!-- <el-input v-if="addMenuForm.parentId"
                      v-model="addMenuForm.parentId"
                      readonly></el-input>
                      <el-input v-else
                      placeholder="根级菜单"
                      readonly></el-input> -->
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item
                label="菜单顺序"
                class="formItem"
                prop="menuOrder"
                :label-width="formLabelWidth"
              >
                <el-input v-model="addMenuForm.menuOrder"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="图标"
                class="formItem"
                :label-width="formLabelWidth"
              >
                <el-input v-model="addMenuForm.menuIcon"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item
                label="菜单类型"
                class="formItem"
                prop="menuType"
                :label-width="formLabelWidth"
              >
                <el-select v-model="addMenuForm.menuType">
                  <el-option label="菜单" value="menu"></el-option>
                  <el-option label="按钮" value="button"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-row type="flex" justify="end">
            <el-col :span="3">
              <el-button type="primary" @click="formSubmit">确 定</el-button>
            </el-col>
            <el-col :span="2">
              <el-button type="info" @click="dialogFormVisible = false"
                >取 消</el-button
              >
            </el-col>
          </el-row>
        </div>
      </el-dialog>
    </el-row>
    <el-dialog title="菜单预览" :visible.sync="menuTreeShow" append-to-body>
      <menuTree />
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from "@/components/table.vue";
import menuTree from "./components/menuTree";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
    menuTree,
  },
  data() {
    //这里存放数据
    return {
      tableConfig: { border: true },
      paginationConfig: { layout: "prev,sizes,pager,next,jumper", total: 0 },
      listMap: {
        pageNum: 1,
        pageSize: 15,
        chargCode: "",
        chargName: "",
        operName: "",
      },
      tableColumnList: [
        //表格字段
        {
          prop: "menuId",
          label: "菜单编号",
        },
        {
          prop: "menuName",
          label: "菜单名称",
        },
        {
          prop: "menuOrder",
          label: "菜单层级",
        },
        {
          prop: "menuIcon",
          label: "图标",
        },
      ],
      tableList: {
        //表格数据
        list: [],
      },
      basicRules: {
        menuId: [
          { required: true, validator: this.checkMenuId, trigger: "blur" },
        ],
        menuName: [
          { required: true, message: "请输入菜单名称", trigger: "blur" },
        ],
        menuType: [
          { required: true, message: "请选择菜单类型", trigger: "blur" },
        ],
      },
      rules: {},
      searchForm: {}, //查询表单
      pageNum: 1,
      pageSize: 15,
      currentPage: 1, //当前页数
      parentId: [],
      dialogFormVisible: false, //新增用户弹窗
      dialogTitle: "", //弹出框名称
      formLabelWidth: "135px", //label宽度
      changeDialogType: null, //0为新增   1为修改
      //新增数据表单
      addMenuForm: {
        editFlag: true, //
        menuIcon: "", //菜单图标
        menuId: "", //菜单id
        menuName: "", //菜单名称
        menuOrder: 0, //菜单等级
        menuType: "", //菜单类型
        menuUrl: "", //菜单地址
        pageNum: 0, //
        pageSize: 0, //
        parentId: "", //父级id
      },
      menuTreeShow: false,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    // this.fake()
    this.getMenu();
    this.queryParentId();
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    // 新增
    handleClickADD(row) {
      if (row) {
        this.addMenuForm.parentId = row.menuId;
      } else {
        this.addMenuForm.parentId = "";
      }
      this.dialogTitle = "新增菜单";
      this.dialogFormVisible = true;
      this.changeDialogType = 0;
      this.rules = this.basicRules;
    },
    // 修改
    handleOrderUpdate(row) {
      this.rules = {
        passWord: this.basicRules.passWord,
      };
      this.addMenuForm = this.$deepClone(row);
      this.dialogTitle = "修改菜单";
      this.dialogFormVisible = true;
      this.changeDialogType = 1;
    },

    //新增、修改表单弹出框提交事件
    formSubmit() {
      this.$refs.menuForm.validate((valid) => {
        if (valid) {
          if (this.changeDialogType == 0) {
            // 新增
            this.insert();
          } else if (this.changeDialogType == 1) {
            // 修改
            this.update();
          }
          this.dialogFormVisible = false;
        } else {
          return false;
        }
      });
    },
    // 新增一条记录
    insert() {
      this.$systemMenuManagement
        .menuInsert(this.addMenuForm)
        .then(() => {
          this.$message({
            message: "新增成功",
            type: "success",
          });
          this.getMenu();
        })
        .catch(() => {
          this.$message.error("操作失败！");
        });
    },
    // 修改一条记录
    update() {
      console.log(this.addMenuForm);
      this.$systemMenuManagement
        .menuUpdate(this.addMenuForm)
        .then(() => {
          this.$message({ message: "修改成功", type: "success" });
          this.getMenu();
        })
        .catch(() => {
          this.$message.error("操作失败！");
        });
    },
    // 新增修改弹出层关闭事件
    popClosed() {
      this.addMenuForm = {
        editFlag: true, //
        menuIcon: "", //菜单图标
        menuId: "", //菜单id
        menuName: "", //菜单名称
        menuOrder: 0, //菜单等级
        menuType: "", //菜单类型
        menuUrl: "", //菜单地址
        pageNum: 0, //
        pageSize: 0, //
        parentId: "", //父级id
      };
    },

    //查询按钮
    queryButton() {
      this.pageNum = 1;
      this.getMenu();
    },
    //获取parentId
    queryParentId() {
      let info = {
        columnName: ["value", "name"],
        tableName: "tb_dict",
        whereStr: [
          {
            colName: "parent_id",
            value: "DDB0648A7F1A43969486C5ECD36E650D",
          },
        ],
      };
      this.$queryDict.queryDict(info).then((res) => {
        this.parentId = res.resultEntity;
      });
    },

    //获取菜单信息   查询
    getMenu() {
      this.searchForm["pageNum"] = this.pageNum;
      this.searchForm["pageSize"] = this.pageSize;
      this.$systemMenuManagement
        .queryListByPage(this.searchForm)
        .then((response) => {
          this.tableList.list = response.resultEntity.list;
          this.paginationConfig.total = response.resultEntity.total;
        });
    },

    // 分页
    getList(change) {
      this.pageSize = change.pageSize;
      this.pageNum = change.pageNum;
      this.getMenu();
    },
    // 重置
    resetForm() {
      this.searchForm = {};
      this.getMenu();
    },
    //删除记录
    handleOrderDelete(row) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$systemMenuManagement
          .menuDelete(row)
          .then(() => {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.getMenu();
          })
          .catch(() => {
            this.$message.error("操作失败！");
          });

        // 删除菜单绑定角色
        let delinfo = [
          {
            menuId: row.menuId,
          },
        ];
        this.$systemRoleManagement
          .delListRoleMenu(delinfo)
          .then(() => {
            this.$message({
              message: "删除成功",
              type: "success",
            });
          })
          .catch(() => {
            this.$message.error("操作失败！");
          });
      });
    },
    // 校验角色id是否重复
    checkMenuId(rule, value, callback) {
      if (value) {
        let info = {
          menuId: value,
          pageNum: 1,
          pageSize: 10,
        };
        this.$systemMenuManagement.queryListByPage(info).then((response) => {
          if (response.resultEntity.total > 0) {
            callback(new Error("菜单编码已存在"));
          } else {
            callback();
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$topSelectHeight: 135px;
.mainbody {
  height: 100%;
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background("blockBg");
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    @include background("blockBg");
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        display: flex;
        padding-left: 91x;
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.formItem {
  height: 60px;
}
</style>
